import React, { useState } from 'react';
import './App.css';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

function App() {
  const [artist, setArtist] = useState(''); // 입력된 가수 이름 상태
  const [songs, setSongs] = useState([]); // 검색 결과 노래 목록
  const [error, setError] = useState(null); // 에러 메시지 상태
  const [fireworks, setFireworks] = useState(false); // 불꽃 이펙트 상태
  const [chartData, setChartData] = useState(null); // 차트 데이터 상태
  const [selectedSong, setSelectedSong] = useState(null); // 선택된 노래 상태
  const [displayMessage, setDisplayMessage] = useState('가수명을 입력해주세요'); // 하단 메시지 상태
  const [page, setPage] = useState(1); // 현재 페이지 상태
  const [hasMore, setHasMore] = useState(true); // 더 가져올 데이터가 있는지 여부

  const handleInputChange = (e) => {
    setArtist(e.target.value);
  };

  // API URL을 환경 변수에서 가져옴
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSongs([]);
    setFireworks(false); // 검색할 때 불꽃 이펙트 초기화
    setChartData(null); // 차트 데이터를 초기화
    setSelectedSong(null); // 선택된 노래 초기화
    setPage(1); // 페이지도 초기화
    setHasMore(true); // 더 가져올 데이터가 있음을 표시

    if (artist.trim() === '') {
      setError('가수명을 입력해주세요');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ artist, page: 1 }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setSongs(data);
        setDisplayMessage(''); // 검색이 완료되면 메시지를 지움
        if (artist === '조희수 사랑해') {
          setFireworks(true); // 특정 가수 검색 시 불꽃 이펙트 실행
        }

        // 더 이상 노래가 없으면 더보기 버튼을 비활성화
        if (data.length < 10) {
          setHasMore(false);
        }
      }
    } catch (err) {
      setError('Failed to fetch data');
    }
  };

  const handleSongClick = (song) => {
    if (selectedSong === song) {
      // 동일한 노래를 클릭하면 차트 숨김 (선택 취소)
      setSelectedSong(null);
      setChartData(null);
    } else {
      let viewsString = song.views;

      // "조회수"와 끝의 "회"를 제거
      viewsString = viewsString
        .replace(/조회수\s?/, '')
        .replace(/회$/, '')
        .trim();
      console.log('조회수 데이터 (처리 후):', viewsString);

      // 쉼표를 제거하고 숫자로 변환
      const views = parseInt(viewsString.replace(/,/g, ''), 10);

      // 조회수 변환 실패 시 처리
      if (isNaN(views)) {
        console.error('조회수 변환 실패:', song.views);
        return;
      }

      // 선택된 노래 업데이트
      setSelectedSong(song); // 선택된 노래 설정

      // 차트 데이터 업데이트
      const chartData = {
        labels: [song.title],
        datasets: [
          {
            label: '조회수',
            data: [views],
            backgroundColor: 'rgba(75, 192, 192, 0.6)', // 투명도 있는 색상
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            borderRadius: 10, // 둥근 모서리
            barThickness: 50, // 바 두께 설정
          },
        ],
      };

      console.log('차트 데이터:', chartData); // 차트 데이터 디버깅용
      setChartData(chartData);
    }
  };

  const loadMoreSongs = async () => {
    const nextPage = page + 1;
    try {
      const response = await fetch(`${API_URL}/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ artist, page: nextPage }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setSongs((prevSongs) => [...prevSongs, ...data]); // 기존 곡에 새로 로드된 곡 추가
        setPage(nextPage); // 페이지 번호 업데이트

        // 더 이상 노래가 없으면 더보기 버튼을 비활성화
        if (data.length < 10) {
          setHasMore(false);
        }
      }
    } catch (err) {
      setError('Failed to fetch data');
    }
  };

  // 차트 옵션: 이쁘게 꾸미기 위한 추가 설정
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value.toLocaleString(); // 천 단위 콤마
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '#333',
          font: {
            size: 16,
          },
        },
      },
    },
    responsive: true, // 반응형
    maintainAspectRatio: false, // 차트 비율 유지하지 않음
  };

  return (
    <div className="App">
      <h1>가수 검색</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" value={artist} onChange={handleInputChange} placeholder="가수 이름을 입력하세요" />
        <button type="submit">검색</button>
      </form>

      {/* 검색 전에는 메시지를 표시하고, 검색 후에는 표시하지 않음 */}
      {songs.length === 0 && <p>{displayMessage}</p>}

      {/* 검색 결과가 있을 때만 목록과 차트를 보여줌 */}
      {songs.length > 0 && (
        <div className={`content-container ${chartData ? 'split' : 'center'}`}>
          {/* 왼쪽 목록 */}
          <div className="results-container">
            {songs.map((song, index) => (
              <div
                key={index}
                className={`result-item ${selectedSong === song ? 'selected' : ''}`}
                onClick={() => handleSongClick(song)}
                style={{ cursor: 'pointer' }}
              >
                <p className="title">
                  <strong>제목:</strong> {song.title}
                </p>
                <p className="views">
                  <strong>조회수:</strong> {song.views}
                </p>
                <a href={song.url} target="_blank" rel="noopener noreferrer">
                  유튜브에서 보기
                </a>
              </div>
            ))}
            {hasMore && (
              <button className="load-more" onClick={loadMoreSongs}>
                더보기
              </button>
            )}
          </div>

          {/* 오른쪽 차트 */}
          {chartData && selectedSong && (
            <div className="chart-container">
              <h2>{selectedSong.title}의 조회수 그래프</h2>
              <div className="chart-wrapper">
                <Bar data={chartData} options={chartOptions} />
              </div>
            </div>
          )}
        </div>
      )}

      {/* 불꽃 이펙트 */}
      {fireworks && (
        <div className="fireworks">
          <div className="firework"></div>
          <div className="firework"></div>
          <div className="firework"></div>
          <div className="firework"></div>
          <div className="firework"></div>
          <div className="firework"></div>
          <div className="firework"></div>
        </div>
      )}
    </div>
  );
}

export default App;
